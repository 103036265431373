/* Gallery section */
.gallery-section {
  padding: 20px;
  text-align: center;
  /* background-color: black; */
  /* position: relative; */
}

.gallery-title {
  /* font-size: 40px;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
  -webkit-text-stroke: 4px #333; */

  font-size: 12rem;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.05);
  transform: translateX(3%) scaleY(1.8);
  z-index: 0;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 40px;
}

/* Gallery container */
.gallery-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust width for your gallery */
  height: 1000px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* background-color: aqua; */
}

/* Single slide */
.gallery-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-slide img {
  width: 100%; /* Image fills the container */
  height: auto;
  border-radius: 10px;
  object-fit: cover; /* Ensure proper aspect ratio */
}

/* Navigation buttons */
.gallery-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 50%;
  z-index: 1;
}

.gallery-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.gallery-button.prev {
  left: 10px;
}

.gallery-button.next {
  right: 10px;
}

/* Navigation dots */
.gallery-navigation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.5);
  border: none;
  cursor: pointer;
}

.dot.active {
  background-color: rgba(100, 100, 255, 1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column;
    padding: 10px;
    height: fit-content;
    /* width: 50%;
    height: fit-content; */
  }

  .gallery-button {
    font-size: 1.5rem;
  }
  .gallery-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .gallery-slide img {
    max-width: 90%;
  }

  .dot {
    width: 10px;
    height: 10px;
  }
  /* .gallery-title {
    font-size: 40px !important;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
  } */
  .gallery-title {
    font-size: 25vw;
    top: 20px;
    letter-spacing: 0.5px;
    transform: translateX(1%) scaleY(1.8);
  }
}
