.rules-section {
  position: relative;
  padding: 100px 0;
  text-align: center;
  font-family: "Baloo 2", sans-serif; /* Default font for the entire section */
  background-color: #f9f0f0; /* Add a background color for visibility */
}

.rules-background-text {
  font-size: 12rem; /* Match the size with the packages section */
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.05); /* Similar opacity */
  position: absolute;
  top: 50px; /* Position similar to the packages section */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center using transform */
  z-index: 0; /* Ensure it's behind the main content */
  user-select: none; /* Prevent text selection */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Container to center the Waiver Card */
.waiver-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures it's always centered on screen */
  padding: 20px;
  margin-top: 80px;
  position: relative; /* Ensure stacking context */
  z-index: 1; /* Place above the background text */
}

/* Card styling */
.waiver-card {
  background: #f8d8e4 !important;
  border-radius: 15px;
  opacity: 1;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333;
  position: relative; /* Relative to ensure proper stacking */
  z-index: 2; /* Ensure it stays above the background text */
}

.waiver-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #d75d72; /* Dark pink for the title */
}

.waiver-rules {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
  font-size: 1rem;
  text-align: left;
}

.waiver-rules li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.waiver-rules li .fa-crown {
  margin-right: 10px;
  color: #d75d72;
  font-size: 1.7rem;
}

.sign-waiver-btn {
  background-color: #d75d72; /* Button color */
  color: white;
  font-size: 1.2rem;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.sign-waiver-btn:hover {
  background-color: #fc847c;
}

/* Responsive styles */
@media (max-width: 768px) {
  .rules-background-text {
    font-size: 17vw;
    top: 15%; /* Adjust position for mobile */
    transform: translate(-50%, -65%) scaleY(1.8);
  }
  .waiver-card {
    padding: 18px; /* Reduced padding on mobile */
    max-width: 100%; /* Make sure it takes full width on small screens */
    margin-top: 0;
  }

  .waiver-title {
    font-size: 1.5rem;
  }

  .waiver-rules li {
    font-size: 0.9rem;
  }

  .sign-waiver-btn {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
