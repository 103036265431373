/* General Styling */
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.contact-section {
  padding: 40px 20px;
  text-align: center;
}

/* .contact-section h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  -webkit-text-stroke: 4px #333;
} */

.contact-section h1 {
  font-size: 12rem; /* Match the gallery title size */
  font-weight: bold; /* Ensure bold text */
  font-family: "Fredoka One", sans-serif; /* Use the same font as the gallery */
  color: rgba(41, 32, 39, 0.05); /* Same translucent color */
  transform: translateX(2%) scaleY(1.5); /* Adjust position and scale to match */
  z-index: 0; /* Ensure it doesn't overlap */
  user-select: none; /* Prevent text selection */
  white-space: nowrap; /* Prevent wrapping */
  letter-spacing: 40px; /* Match the spacing from the gallery */
  text-align: center; /* Ensure the title is centered */
  margin-bottom: 40px; /* Add spacing below the title */
}

.contact-content {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.info-box,
.form-container {
  flex: 1;
  min-width: 600px;
  max-width: 45%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-container {
  background-color: #f4ead7;
}

.map-container {
  flex: 1;
  min-width: 500px;
  max-width: 45%;
  height: 600px;
  background-color: #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
/* .map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
} */
.map-container a {
  width: 100%;
  height: 100%;
  border: 0;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
}

.contact-form input,
.contact-form textarea {
  background-color: #fcf8f1;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 400px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  background-color: #f1e0c0;
  border-color: #f9c1a2;
  outline: none;
}

button {
  padding: 10px 20px;
  background-color: #e49b0f;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
}

button:hover {
  background-color: #d8ba83;
}

iframe {
  width: 100%;
  height: 100%;
}

/* Contact Information Box */
.info-box {
  background-color: #ffdead;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info-box h2 {
  margin-bottom: 10px;
  color: #333;
}

.info-box p {
  margin: 8px 0;
  font-size: 20px;
  color: #555;
}

.info-box .icon-phone,
.info-box .icon-location,
.info-box .icon-email,
.info-box .icon-instagram {
  margin-right: 10px;
  color: #333;
  font-size: 18px;
}

/* Social Media Icons */
.social-media {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-media a {
  font-size: 32px;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #ff4747;
}

.facebook {
  color: #1877f2;
}

.instagram {
  color: #e1306c;
}

.tiktok {
  color: #010101;
}

/* .footer {
  font-size: 20px;
  color: #666;
  text-align: center;
  padding: 10px 0;
  margin-top: 40px;
  border-top: 1px solid #ddd;
}

.footer p {
  margin: 0;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}
/* 
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
    padding: 0 10px;
  }

  .form-container,
  .map-container {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .info-box {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .info-box h2 {
    font-size: 20px;
    text-align: center;
  }

  .map-container iframe {
    width: 100%;
    height: 250px;
  }

  .social-media a {
    font-size: 24px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
    width: 300px;
    padding: 10px;
  }
  .contact-form input:focus,
  .contact-form textarea:focus {
    background-color: #f1e0c0;
    border-color: #f9c1a2;
    outline: none;
  }

  .contact-form button {
    font-size: 20px;
    padding: 12px 20px;
    margin-bottom: 20px;
  }

  h1,
  .contact-form h2,
  .info-box h2 {
    font-size: 24px;
    word-wrap: break-word;
  }

  .contact-content {
    gap: 20px;
  }

  .form-container,
  .map-container,
  .info-box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .info-box p {
    font-size: 16px;
  }
} */
@media (max-width: 768px) {
  .contact-section {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .contact-section h1 {
    font-size: 3rem; /* Smaller font size for mobile */
    transform: translateX(2%) scaleY(1.8); /* Adjust transform for mobile */
    letter-spacing: 10px; /* Reduce letter spacing */
  }
  /* .contact-section h1 {
    margin-bottom: 40px;
    -webkit-text-stroke: 2px #333;
    font-size: 36px;
  } */
  .contact-content {
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    padding: 0 5px;
  }

  .form-container,
  .info-box {
    width: 100%;
    min-width: 300px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: none;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 24px;
    width: 100%;
    padding: 10px;
  }
  .contact-form input:focus,
  .contact-form textarea:focus {
    background-color: #f1e0c0;
    border-color: #f9c1a2;
    outline: none;
  }
  .contact-form label {
    font-size: 14px;
  }
  .info-box {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    gap: 20px; /* Add spacing between details and map */
  }
  /* .map-container {
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .map-container iframe {
    width: 100%;
    height: 150px; 
    border: none; 
    display: block; 
  } */
  .map-container {
    width: 250px !important;
    height: 150px !important;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    background-color: transparent;
  }

  .map-container a {
    width: 250px !important;
    height: 150px !important;
    max-width: 100%;
    max-height: 100%;
    border: none;
    display: block;
  }
  /* .map-container iframe {
    width: 250px !important;
    height: 150px !important;
    max-width: 100%;
    max-height: 100%;
    border: none;
    display: block;
  } */

  .info-box p {
    font-size: 12px;
    text-align: left;
  }

  h1,
  .contact-form h2,
  .info-box h2,
  .form-container h2 {
    font-size: 16px;
    font-weight: bolder;
  }

  button {
    font-size: 16px;
    padding: 12px 15px;
  }
}
