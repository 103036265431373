@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

/* Overall section for the packages */
.packages-section {
  position: relative;
  padding: 100px 0;
  text-align: center;
  font-family: "Baloo 2", sans-serif; /* Default font for the entire section */
}

/* Background text behind the packages */
.packages-background-text {
  font-size: 12rem;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.05);
  position: absolute;
  top: -80px;
  left: 51.5%;
  transform: translateX(-50%);
  z-index: 0;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 40px;
}

/* Container for the full package card */
.package-card-container {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  gap: 3rem; /* Add spacing between rows */
}

/* Row for party packages and Daily Pass & Membership */
.package-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem; /* Spacing between packages */
  width: 100%;
}

/* Main card styling */
.package-card {
  background-color: #d7f0f7;
  border-radius: 25px;
  padding: 50px 40px;
  text-align: center;
  color: rgba(41, 32, 39, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1100px;
}

/* Regular package styling */
.regular-package {
  text-align: left;
  padding: 0 20px;
  max-width: 600px;
}

.regular-package h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.package-time {
  margin-bottom: 20px;
}

.package-time p {
  font-size: 1.5rem;
}

.package-time ul {
  list-style: none;
  padding-left: 0;
}

.package-time li {
  font-size: 1.2rem;
}

.membership-info p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #333;
}

/* Individual package boxes */
.package-box {
  background: #d7f0f7;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.package-box .open-play-hours {
  font-size: 1.7rem; /* Bigger font size for "Open Play Hours" */
  font-weight: bold;
  margin-bottom: 5px; /* Space between the label and the time */
}

.package-box .open-play-time {
  font-size: 1.7rem;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 15px;
}

.package-box .pricing-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
}
.package-box .note {
  font-size: 0.9rem;
  color: #444;
  margin-top: 10px;
}

.package-box .pricing-list li {
  font-size: 1rem;
  margin-bottom: 8px;
}

.package-box h3 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.package-box h4 {
  font-size: 1.8rem;
  color: #fa9d96;
  margin-bottom: 15px;
}

.package-box p {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 20px;
  text-align: center;
}
.package-box.monthly-membership {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}
.package-box.monthly-membership ul.pricing-list {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 0;
  list-style-type: none;
}
.package-box.monthly-membership li:first-child {
  font-size: 0.9rem;
  margin-bottom: 10px;
}
.package-box.monthly-membership p {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 15px;
  text-align: justify;
}

/* Details button */
.details-button {
  background-color: #fa9d96;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.details-button:hover {
  background-color: #fc847c;
  color: white;
}

/* Row spacer */
.row-spacer {
  height: 2rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .packages-background-text {
    font-size: 17vw;
    top: 20px;
    letter-spacing: 0.5px;
    transform: translateX(-50%) scaleY(1.8);
  }

  .package-card-container {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    margin: auto; /* Center within the parent */
  }

  .package-row {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
    gap: 2rem; /* Space between cards */
    width: 100%;
  }

  .package-box {
    margin: auto; /* Center individual cards */
    width: 90%; /* Adjust width to fit smaller screens */
  }
}
