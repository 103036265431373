.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end !important; */
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.navbar ul {
  list-style: none; /* Remove default bullets */
  margin: 0;
  padding: 0;
}

.navbar li {
  display: inline-block; /* Display items in a row */
  margin: 0 15px; /* Add spacing between items */
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px;
  width: auto;
  object-fit: contain;
  margin-right: 20px;
}

.menu {
  /* list-style: none;
  display: flex;
  gap: 20px;
  margin-left: auto; */
  list-style: none;
  display: flex;
  gap: 20px;
  margin-left: auto; /* Ensure right alignment */
  justify-content: flex-end;
}

.menu li {
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu li:hover {
  color: #ff6347;
}

.book-now-container {
  margin-left: 20px;
}

.book-now-btn {
  background: linear-gradient(to bottom, #f4dcab, #eaa377);

  /* background-image: linear-gradient(
    45deg,
    #ffc000,
    #e49b0f,
    #e8bb9f,
    #f4bb44,
    #f0e68c,
    #e49b0f,
    #ffc000
  ); */
  margin-bottom: 5px;
  color: white;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.book-now-btn:hover {
  /* background-image: linear-gradient(
    45deg,
    #ffc000,
    #e49b0f,
    #f0e68c,
    #f4bb44,
    #e8bb9f,
    #e49b0f,
    #ffc000
  ); */
  background: linear-gradient(to bottom, #d4b581, #e4a47c);
  transform: scale(1.05);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-left: auto;
  z-index: 2000;
}

.hamburger div {
  width: 25px;
  height: 2px;
  background-color: white;
}
.hamburger .bar {
  width: 35px;
  height: 5px;
  margin: 2px 0;
  transition: all 0.3s ease;
  border-radius: 10px;
}
.icon-phone {
  margin-right: 8px; /* Add spacing between the icon and the number */
  font-size: 18px;
  background: linear-gradient(to bottom, #f4dcab, #eaa377);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent; /* Ensure the gradient is applied to the text */
}

.phone-link {
  font-size: 18px;
  background: linear-gradient(to bottom, #f4dcab, #eaa377);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-decoration: none;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.phone-link:hover {
  background: linear-gradient(to bottom, #d4b581, #e4a47c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
  }

  .menu.active {
    display: flex;
  }

  .menu li {
    margin-bottom: 10px;
    text-align: center;
  }

  .book-now-container {
    margin-left: auto;
  }

  .hamburger {
    display: flex;
  }

  .book-now-container .book-now-btn {
    display: block;
  }
}
