/* Navbar Spacing */
.booking-page-container {
  padding-top: 60px; /* Height of fixed navbar */
  padding-bottom: 60px; /* Height of fixed navbar */
}

.booking-wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* Booking Form Container */
.booking-form-container {
  position: relative;
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* z-index: 2; */
}

/* Form Heading */
.center-heading {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* Flexbox Layout for Rows */
.form-group.row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between inputs */
  margin-bottom: 20px;
}

.form-group-half {
  flex: 1;
}

/* Inputs and Textarea */
.booking-form-container input,
.booking-form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.booking-form-container textarea {
  min-height: 100px;
  resize: vertical;
}

/* Submit Button */
.booking-form-container button {
  background-color: #e02440d8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: 20px auto 0;
}

.booking-form-container button:hover {
  background-color: #ff4747;
}

/* Thank-You Message */
.thank-you-message {
  text-align: center;
  background-color: #f7cecb;
  padding: 30px;
  margin: 50px auto;
  border-radius: 15px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thank-you-message h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;
}

.thank-you-message p {
  color: #555;
  font-size: 18px;
}
/* Uniform Height for Inputs */
.booking-form-container input,
.booking-form-container textarea,
.time-input select {
  height: 40px; /* Ensure consistent height */
  padding: 10px; /* Uniform padding */
  font-size: 14px;
  border-radius: 10px; /* Match other input styles */
  border: 1px solid #ccc;
  box-sizing: border-box; /* Ensure padding doesn't increase height */
}

/* Align Dropdowns with Inputs */
.time-input {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between elements */
  margin-top: 6px;
}

.time-input select {
  vertical-align: middle; /* Aligns with the date box */
  padding: 0 10px; /* Add padding inside the dropdowns */
  font-size: 14px; /* Keep font size consistent */
}

/* Adjust Time Separator */
.time-separator {
  font-size: 16px;
  font-weight: bold;
  line-height: 40px; /* Match the input height for alignment */
  margin: 0 5px;
}

.booking-page-container {
  position: relative;
  min-height: 100vh;
  background: url("../media/KK_Banner_cut.png") center/cover no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  padding-top: 60px; /* Adjust for navbar */
  padding-bottom: 60px; /* Adjust for footer spacing */
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-page-container {
    min-height: 100vh;
    background: url("../media/KK_Banner_cut.png") center/cover no-repeat;
    background-size: auto auto; /* Ensure the image scales to cover the container */
    background-position: center center;
    background-color: #fff;
  }
  .booking-form-container {
    position: relative;
    width: 80%;
    padding: 15px;
    margin: 20px auto;
    overflow-y: auto; /* Allow scrolling if content overflows vertically */
    box-sizing: border-box; /* Make sure padding is included in the height calculation */
  }

  .center-heading {
    font-size: 20px;
  }

  .form-group.row {
    flex-direction: column;
    gap: 15px;
  }

  .form-group-half {
    margin-right: 0;
  }

  .booking-form-container input,
  .booking-form-container textarea {
    font-size: 12px;
    padding: 8px;
  }

  .booking-form-container button {
    font-size: 14px;
    padding: 8px 15px;
  }
}
