.privacy-policy-container {
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
  background-color: rgb(219, 200, 115);
  min-height: 100vh; /* Ensure the container fills the entire viewport height */
  box-sizing: border-box; /* Includes padding in height calculation */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body,
html {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: rgb(219, 200, 115);
}

.privacy-policy-container h1 {
  /* font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px; */
  font-size: 5rem;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.08);
  transform: translateX(-10%) scaleY(2);
  z-index: 0;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 45px;
}

.privacy-policy-container h2 {
  font-size: 1.75rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy-container p,
.privacy-policy-container ul {
  font-size: 1rem;
  line-height: 1.6;
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

footer {
  text-align: center;
  font-size: 0.875rem;
}
/* Responsive styles */
@media (max-width: 768px) {
  .privacy-policy-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Fredoka One", sans-serif;
    color: rgba(12, 12, 12, 0.08);
    transform: translateX(2%) scaleY(2);
    z-index: 0;
    user-select: none;
    white-space: normal; /* Allow text wrapping */
    letter-spacing: 5px; /* Reduce character spacing */
    word-spacing: -5px !important; /* Adjust space between words */
    text-align: center;
    margin-top: 70px;
  }
}
