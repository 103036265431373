/* .footer {
  font-size: 20px;
  color: #666;
  text-align: center;
  padding: 10px 0;
  margin-top: 40px;
  border-top: 1px solid #ddd;
}

.footer p {
  margin: 0;
} */
.privacy-link {
  display: block;
  margin-top: 10px; /* Add spacing below the paragraph */
  color: white;
  text-decoration: underline;
  font-size: 0.9rem; /* Adjust font size */
}

.privacy-link:hover {
  text-decoration: underline; /* Underline on hover */
  color: #0056b3; /* Darker shade on hover */
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Match the height of the navbar */
  background-color: rgba(0, 0, 0, 0.8); /* Same color as the navbar */
  color: white; /* Match the text color */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; /* Center the content vertically */
  padding: 0 20px; /* Match the navbar padding */
  z-index: 1000; /* Ensure it stays above other elements */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  font-size: 18px; /* Match the font size of the menu items */
}

.footer p {
  margin: 0;
  font-size: 18px;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer p {
    margin: 0;
    font-size: 14px; /* Match the navbar font size */
    color: white; /* Ensure the footer text is visible */
  }
  .privacy-link {
    font-size: 0.9rem;
  }
}
