/* Ensure the body and html allow scrolling */
html,
body {
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  font-family: "Baloo 2", sans-serif; /* Default font for the entire section */
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

/* Parallax Effect */
.home-section {
  height: 100vh;
  /* background-image: url("/13.JPG"); */
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  position: relative;
  overflow: hidden;
}

.section {
  min-height: 100vh;
  padding: 20px;
  background-color: white;
}

/* Image Container */
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the full viewport */
  z-index: 1;
  overflow: hidden;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container fully without distortion */
}
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* inset: 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.overlay-text h1 {
  font-size: 62px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #ffffff;
  -webkit-text-stroke: 4px white;
}

.overlay-text p {
  font-size: 1.25rem;
  font-weight: normal;
  margin-top: 10px;
  color: #ffffff;
  -webkit-text-stroke: 1.8px white;
}

/* Section Background Colors */
#gallery {
  background-color: #ffefdb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#packages {
  background-color: #d1e7dd;
}

#waiver {
  background-color: #cfe2f3;
}

#contact {
  background-color: #fce5cd;
}

#rules-waiver {
  background-color: #f9f0f0;
}

/* Adjust navbar on small screens */
@media (max-width: 768px) {
  .overlay-text h1 {
    font-size: 2rem;
    margin-bottom: 15px;
    line-height: 1;
    -webkit-text-stroke: 2px white;
  }

  .overlay-text p {
    font-size: 1rem;
    line-height: 1;
    -webkit-text-stroke: 0.8px white;
  }
  .navbar {
    height: 50px;
    padding: 0 10px;
  }

  .section {
    flex-direction: column;
    padding-top: 10px;
  }
  .home-section {
    background-attachment: scroll;
  }
}
