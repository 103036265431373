/* FAQ Section Styling */
.faq-section {
  padding: 40px 20px;
  max-width: 1000px;
  margin: 0 auto;
  font-family: "Fredoka One", sans-serif;
  position: relative;
  text-align: center;
}

.faq-title {
  /* font-size: 36px;
  color: #f0609a;
  text-align: center; 
  margin-bottom: 30px;
  font-weight: bold; */
  /* font-size: 12rem;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.05);
  transform: translateX(25%) scaleY(1.5);
  z-index: 0;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 40px; */

  font-size: 12rem;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  color: rgba(41, 32, 39, 0.05);
  position: absolute; /* Position the title relative to the nearest positioned parent */
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(1.5);
  z-index: 0;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 40px;
  margin-bottom: 30px;
}

/* Change to a two-column layout */
.faq-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 15px 70px; /* Space between rows and columns */
  margin-top: 200px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 15px 0;
  font-size: 18px;
  font-weight: bold;
  font-family: "Fredoka One", sans-serif;
  text-align: left;
  cursor: pointer;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-icon {
  font-size: 24px;
  color: #ff7300; /* Default color */
  transition: transform 0.3s ease, color 0.3s ease; /* Add transition for color */
}

.faq-icon.active {
  transform: rotate(90deg); /* Rotate when active */
  color: #00cc00; /* Change color to green when active */
}

.faq-icon .faCheckCircle {
  color: inherit; /* Inherit color from parent for easier control */
  font-size: 24px; /* Adjust size */
}

.faq-icon.active .faCheckCircle {
  color: inherit; /* Inherit active color */
  transition: color 0.3s ease; /* Add color transition for smooth change */
}

.faq-answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.faq-answer.show {
  max-height: 1000px;
}

.faq-answer p {
  font-size: 16px;
  color: #555;
  padding: 10px 0;
  margin: 0;
}

/* Clean, no background hover effect */
.faq-question:hover {
  background-color: transparent;
}

/* Responsive design - stack columns on smaller screens */
@media (max-width: 768px) {
  .faq-container {
    grid-template-columns: 1fr;
  }
  .faq-title {
    font-size: 6rem;
    font-weight: bold;
    font-family: "Fredoka One", sans-serif;
    color: rgba(41, 32, 39, 0.05);
    position: absolute; /* Position the title relative to the nearest positioned parent */
    top: 7%;
    left: 50%;
    transform: translate(-45%, -50%) scaleY(1.8);
    z-index: 0;
    user-select: none;
    white-space: nowrap;
    letter-spacing: 40px;
    margin-bottom: 30px;
  }
}
