.packages-detail-section {
  padding: 60px;
  padding-top: 100px;
  background: linear-gradient(
    90deg,
    #f0609a,
    hsl(335, 100%, 91%),
    #d7f0f7,
    #71b9f5,
    #dcddee,
    #a7a9ab,
    #98cefa
  );
  background-size: 200% 200%;
  animation: gradientShift 10s ease infinite;
  min-height: 100vh;
  text-align: center;
  width: 100%;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.section-title {
  font-size: 3.5rem;
  margin-bottom: 10px;
  color: rgba(41, 32, 39, 1);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}
.section-title2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: rgba(41, 32, 39, 1);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-template-rows: auto;
}

.package-detail-card {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease-in-out;
}
.book-now-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-height {
  height: 450px;
}

.package-detail-card:not(.fixed-height) {
  height: auto;
  min-height: 350px;
}

.package-detail-card:not(.fixed-height) {
  margin-bottom: 20px;
}

.package-detail-card:hover {
  transform: translateY(-10px);
}

.package-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #4b4b4b;
}

.price-box {
  background-color: #9bdae1;
  padding: 15px;
  border-radius: 8px;
  color: white;
  margin-bottom: 15px;
}

.package-price {
  font-size: 2.5rem;
}

.package-tax {
  font-size: 0.9rem;
}

.package-details {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.package-details li {
  font-size: 12px;
  color: #666;
  display: flex; /* Use flexbox for even spacing */
  justify-content: space-between; /* Spread the label and value evenly */
  align-items: center; /* Align label and value vertically */
  /* padding: 5px 0;  */
  padding: 2px 0 2px 0;
}
.package-details li span {
  flex-shrink: 0; /* Prevent the value text from shrinking */
  text-align: right; /* Align the value text to the right */
}
.package-details li.no-label span {
  margin-left: auto; /* Push the value to the right */
  text-align: left; /* Align value text to the left (optional) */
}

/* Styling for additional info */
.additional-info {
  background-color: #f6f4f4;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}
.additional-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.additional-info li {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
  display: flex;
  /* justify-content: flex; */
  align-items: flex-start;
  gap: 3px;
  /* word-wrap: break-word; */
  /* padding: 5px 0; */
}
.additional-info li span {
  text-align: left; /* Ensure text aligns to the left */
  flex: 1; /* Allow the text to take up remaining space */
}

.check-icon {
  flex-shrink: 0;
  color: #9bdae1;
  margin-top: 2px;
  margin-right: 8px;
}
.packages-grid-2 {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 60% 40%;
  gap: 20px;
  margin-top: 40px;
}
.packages-grid-3 {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 50% 50%;
  gap: 20px;
  margin-top: 40px;
}
.packages-grid-4 {
  display: grid;
  grid-template-columns: 1fr; /* Single-column layout for policies */
  gap: 20px;
  margin-top: 40px;
  width: 100%;
}

/* Make the layout responsive */
@media (max-width: 1200px) {
  .packages-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .packages-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .section-title {
    font-size: 2.5rem;
  }
  .section-title2 {
    font-size: 1rem;
  }

  .package-detail-card {
    padding: 20px;
    width: 100%;
  }
}

/* @media (max-width: 480px) {
  .packages-grid {
    grid-template-columns: 1fr;
  }
} */
@media (max-width: 480px) {
  .packages-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
  .packages-grid-2 {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
  .packages-grid-3 {
    grid-template-columns: 1fr;
  }
  .packages-grid-4 {
    grid-template-columns: 1fr;
  }
  .package-book-now {
    display: none; /* Hide by default on mobile */
  }
  .section-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgba(41, 32, 39, 1);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  }
  .packages-detail-section {
    padding-top: 80px;
  }
  .package-title {
    font-size: 1rem;
  }
  .price-box {
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 6px;
  }

  .package-price {
    font-size: 1.2rem;
  }

  .package-tax {
    font-size: 0.7rem;
  }
  .package-details {
    list-style: none;
    padding: 0;
    margin-bottom: 0px;
  }

  .package-details li {
    font-size: 10px;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 0;
  }
  .additional-info {
    background-color: #f6f4f4;
    padding: 5px;
    border-radius: 8px;
    margin-top: 10px;
  }
  .additional-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .additional-info li {
    font-size: 10px;
    margin-bottom: 3px;
    color: #555;
    display: flex;
    align-items: flex-start;
    gap: 1px;
  }
  .additional-info li span {
    text-align: left;
    flex: 1;
  }

  .check-icon {
    flex-shrink: 0;
    color: #9bdae1;
    margin-top: 2px;
    margin-right: 2px;
  }
}
